<template>
  <p class="tab-title">
    <img
      :src="src"
      :alt="title"
      width="70"
      height="70"
      class="tab-title__img"
    >
    <span class="tab-title__name">{{ title }}</span>
  </p>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      src: require('@/assets/img/img__' + this.img + '.png')
    }
  }
}
</script>

<style scoped>
</style>
