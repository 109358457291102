
import { Component, Prop, Ref, Watch } from 'nuxt-property-decorator'
import vSelect from 'vue-select'
import DocVu from '@pp-frontend/shared-packages/classes/doc-value/DocVu'
import DocCtc from '@pp-frontend/shared-packages/classes/doc-value/DocCtc'
import DocUin from '@pp-frontend/shared-packages/classes/doc-value/DocUin'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import { FieldsId } from '@pp-frontend/shared-packages/types/enum/FieldsId'
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'
import DocGrz from '@pp-frontend/shared-packages/classes/doc-value/DocGrz'
import GrzGibddSearch from '@pp-frontend/shared-packages/classes/landing/gibdd/GrzGibddSearch'
import BaseInput from '~/components/UI/Input/BaseInput.vue'
import InputLicencePlate from '~/components/UI/Input/InputLicencePlate.vue'

enum errorAvailableValue {
  manyRequest = 'MANY_REQUEST',
  backendError = 'BACKEND_REQUEST',
  serverError = 'SERVER_ERROR'
}

@Component({
  components: {
    vSelect,
    BaseInput,
    InputLicencePlate,
    BeforeLeavePopup: () => import(/* webpackPrefetch: true */ '@pp-frontend/popups/component/BeforeLeavePopup.vue')
  }
})
export default class SearchFormGibdd extends SearchFormAbstract {
  @Ref() docField!: HTMLInputElement
  @Prop() showMeta!: boolean
  @Prop() isIframe!: boolean
  popupComponent = () => import('~/components/popup/Popup.vue')

  allowServices = this.$allowServices()
  docTypeList = [
    {
      label: 'Госномер автомобиля',
      value: new DocGrz(),
      fieldId: FieldsId.PLATE_NUM
    },
    {
      label: 'Свидетельство о регистрации',
      value: new DocCtc(),
      fieldId: FieldsId.CTC
    },
    {
      label: 'Водительское удостоверение',
      value: new DocVu(),
      fieldId: FieldsId.VU
    },
    {
      label: 'Номер постановления (УИН)',
      value: new DocUin(),
      fieldId: FieldsId.UIN
    }
  ]

  selectedType: any = this.docTypeList[0]
  isError: string = ''
  errorDocVal: string = ''
  errorServer: string = ''
  isLoading: boolean = false
  errorAvailableValue = errorAvailableValue

  @Watch('allowServices', { deep: true })
  allowServicesHandler () {
    if (!this.allowServices.ctc_by_grz) {
      this.selectedType = this.docTypeList[1]
      this.docTypeList.shift()
    }
    return this.allowServices
  }

  @Watch('selectedType')
  onTypeChange () {
    this.errorServer = ''
    this.$v.formData.$reset()
  }

  keydownHandler (map) {
    return {
      ...map,
      8: (e) => {
        e.preventDefault()
      }
    }
  }

  get formData () {
    this.$sentry.configureScope((scope: any) => {
      scope.setExtra('docTypeList', this.docTypeList)
    })
    return this.selectedType.value
  }

  get placeholderText () {
    switch (this.formData.docType()) {
      case 'uin':
        return 'Введите УИН начисления'
      case 'vu':
        return 'Введите номер документа'
      default:
        return 'Введите номер свидетельства'
    }
  }

  get errorText () {
    switch (this.formData.docType()) {
      case 'uin':
        return 'Формат поля УИН: 20 или 25 цифр'
      case 'ctc':
        return 'Формат поля CTC: 00 АА 000000'
      case 'vu':
        return 'Формат поля ВУ: 00 АА 000000'
      case 'passport':
        return 'Формат паспорта: 0000 000000'
      default:
        return 'Поле обязательно'
    }
  }

  get enableBeforeSubmitCheck () {
    return this.selectedType.fieldId !== FieldsId.UIN
  }

  getType (): string {
    return 'gibdd'
  }

  mounted () {
    this.$lastActivity.setEventListener(Events.SEARCH_FORM_SUBMIT)
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      const getDocType = () => error.payerData[0].document_type
      this.errorDocVal = error.error.response.data.error.document_value[0]
      this.isError = getDocType()
      this.formData.docType = getDocType
    })
    this.allowServicesHandler()
  }

  afterSubmitBehavior () {
    if (this.isIframe) {
      const params: any = Object.assign({
        document_type: this.formData.docType(),
        lead_source_id: this.$route.query.lead_source_id
      }, this.formData)
      const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      const url = `https://gos-oplata.ru/search/${this.getType()}?${queryString}`

      if (this.$route.query.target === 'parent') {
        // @ts-ignore
        window.parent.location = url
      } else {
        const win: any = window.open(url, '_blank')
        win.focus()
      }
    } else if (this.formData.docType() === 'licence_plate') {
      this.isLoading = true

      new GrzGibddSearch().validate(this.formData).then(() => {
        this.$router.push({
          path: '/search/' + this.getType(),
          query: (Object.assign({
            document_type: this.formData.docType(),
            lead_source_id: this.$route.query.lead_source_id
          }, this.formData) as any)
        })
      }).catch((err) => {
        switch (err.response.status) {
          case 422:
            this.errorServer = errorAvailableValue.backendError
            break
          case 429:
            this.errorServer = errorAvailableValue.manyRequest
            break
          case 500:
            this.errorServer = errorAvailableValue.serverError
            break
        }
      }).finally(() => {
        this.isLoading = false
      })
    } else {
      this.$router.push({
        path: '/search/' + this.getType(),
        query: (Object.assign({
          document_type: this.formData.docType(),
          lead_source_id: this.$route.query.lead_source_id
        }, this.formData) as any)
      })
    }

    return Promise.resolve()
  }
}
