<template>
  <BaseInput
    id="licencePlate"
    v-tooltip.top="{
      html:true,
      theme:'info-text',
      distance:8,
      container:tooltipContainer,
      content:LICENCE_PLATE_TOOLTIP,
      triggers: [],
      shown: isTooltipShown || showTooltip
    }"
    mask="f ### ff ###"
    v-bind="$attrs"
    v-on="$listeners"
    @keyup.native="handleKeyup"
    @focusout.native="isTooltipShown = false"
  />
</template>

<script>
import BaseInput from '~/components/UI/Input/BaseInput.vue'

const ALLOWED_CHARACTERS_REGEX = /[0-9]|[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/

export default {
  name: 'InputLicencePlate',
  components: { BaseInput },
  props: {
    showTooltip: {
      type: Boolean,
      default: false
    },
    tooltipContainer: {
      type: String,
      default: 'body'
    }
  },
  data () {
    return {
      isTooltipShown: false,
      LICENCE_PLATE_TOOLTIP: `
            Допустимые символы для поля:<br>
            <ul>
              <li>цифры - <span>0-9</span>;</li>
              <li>буквы - <span>А, В, Е, К, М, Н, О, Р, С, Т, У, Х</span></li>
            </ul>
            <div style="margin-top: 4px;">
              Формат гос. номеров: <br>
              <span>A 000 AA 000</span> или <span>A 000 AA 00</span>
            </div>
          `
    }
  },
  methods: {
    handleKeyup (e) {
      if (!e.key) {
        return
      }
      this.isTooltipShown = !e.key.match(ALLOWED_CHARACTERS_REGEX)
    }
  }
}
</script>
