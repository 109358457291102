var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseInput',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
    html:true,
    theme:'info-text',
    distance:8,
    container:_vm.tooltipContainer,
    content:_vm.LICENCE_PLATE_TOOLTIP,
    triggers: [],
    shown: _vm.isTooltipShown || _vm.showTooltip
  }),expression:"{\n    html:true,\n    theme:'info-text',\n    distance:8,\n    container:tooltipContainer,\n    content:LICENCE_PLATE_TOOLTIP,\n    triggers: [],\n    shown: isTooltipShown || showTooltip\n  }",modifiers:{"top":true}}],attrs:{"id":"licencePlate","mask":"f ### ff ###"},nativeOn:{"keyup":function($event){return _vm.handleKeyup.apply(null, arguments)},"focusout":function($event){_vm.isTooltipShown = false}}},'BaseInput',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }